h2, h3 {
    margin: 0;
    padding: 0;
    font-size: 2.4rem;
}

h3 {
    font-size: 2.0rem;
}

p {
    margin: 0;
    padding: 0.8rem 0 1.6rem 0;
    font-size: 1.6rem;
}