.overview-card {
    cursor: pointer;
    display: block;

    .card {
        box-sizing: border-box;
        margin: 0;
        padding: 8px;
        max-height: 136px;
        overflow: hidden;
        transition: max-height 0.24s ease-in-out;

        .feedback {
            overflow: hidden;
            max-height: 0;

            a {
                font-weight: bold;
                text-decoration: none;
            }
        }
    }

    &.active.has-feedback {
        .card {
            max-height: 1000px;
            z-index: 1;

            .feedback {
                max-height: 1000px;
                padding: 0 8px;
            }
        }
    }

    &.active,
    &:hover {
        .card {
            outline: 1px solid #009FDA;
        }
    }

    header {
        font-weight: bold;
        font-size: 16px;
    }

    .detail-link {
        font-size: 16px;
        text-align: right;
        color: #009FDA;
        margin-top: 28px;
        margin-bottom: 6px;
        margin-right: 8px;

        &:before {
            display: inline-block;
            content: ' ';
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6bm9uZTt9LmJ7ZmlsbDojMDA5ZmRhO308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTMgLTY0KSI+PHBhdGggY2xhc3M9ImEiIGQ9Ik0wLDBIMjRWMjRIMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUzIDY0KSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMjkwLjIsMTYuMjcybC0xLjEyLTEuMjY0LDIuNC0yLjExMmgtNS43MTJWMTEuMDRoNS43MTJsLTIuNC0yLjExMiwxLjEyLTEuMjY0LDQuNDQ4LDQuMjcyVjEyWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIyNS43NjggNjQuMzM2KSIvPjwvZz48L3N2Zz4=');
            vertical-align: middle;
            height: 24px;
            width: 24px;
        }
    }
}
