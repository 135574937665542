.cutoff-plot {
    font-size: 12px;
    display: block;

    .cutoff-line {
        stroke: rgb(44, 44, 44);
        stroke-width: 2px;
        stroke-dasharray: 8, 4;
    }
}
