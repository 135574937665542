footer.review-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #009fda;
    color: #FFFFFF;
    left: 0;
    font-weight: bold;

    ul, ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    ol {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        li {

            span,
            a {
                padding: 8px 16px;
                display: inline-block;
            }

            a {
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
}
