.accordion {

  button {
      cursor: pointer;
      padding: 18px 18px 18px 0;
      width: 100%;
      text-align: left;
      outline: none;
      transition: 0.4s;
      color: #707070;
      font-size: 1.6rem;
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      svg {
        font-size: 24px
      }

  }

  .inactiveButton {
    border-bottom: 1px solid black;
  }


  .panel {
    padding: 0 18px;
    height: 0;
    overflow: hidden;
    border-top: solid black 1px;
  }

  div {
    transition: height 0.25s ease-in;
  }


  .expendOpen {
    transform: rotate(180deg);
  }

  .active {
    display: block;
    border-bottom: solid black 1px;

    button {
      text-transform: uppercase;
      color: #8625FF;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      border: 0;
      display: block;
    }

  }
}


