ol.review-nav {
    list-style: none;
    display: flex;
    padding: 0 16px 16px 16px;
    margin: 0;
    justify-content: space-between;

    li {
        a, i {
            font-size: 1.6rem;
            color: #333333;
        }

        a.inactive,
        a.inactive i {
            color: #666666;

        }
    }
}
