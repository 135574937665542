// Default Nav component

/* LAYOUT */

@media screen and (max-width: 1280px) {

    header {
        //padding-top: 68px;

        nav:first-of-type {
            position: fixed;
            top: 0;
            z-index: 1;
            width: 100%;
        }
    }
}


nav.diagnostic-nav {

    // Should do this globably - class?
    h2 {
        // placeholder
        position: absolute;
        left: -10000px;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }

    // 'Hamburger'
    button {
        // Make sure only visible if att is passed
        // Style nav bar accordingly
        &:not([aria-haspopup]) {
            display: none;

            + ol {
                background: #FFFFFF;

                li {
                    display: inline-block;
                }
            }
        }
    }

    ol {
        // Reset
        margin: 0;
        padding: 0;

        li {
            // Reset
            margin: 0;
            padding: 0;
            list-style: none;
        }

        a {
            display: block;
            text-decoration: none;
        }
    }
}

/* THEME */

nav.diagnostic-nav {
    background: #FFFFFF;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    position: relative;

    // 'Hamburger'
    button {
        color: #2C2C2C;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        padding: 16px 8px;
        outline: none;

        // transitions are nice
        i,a { transition: all ease-in-out 0.4s; }

        i {
            font-size: 32px;
            vertical-align: middle;
            margin: 0 16px 0 8px;
            cursor: pointer;
        }

        // haspopup att is 'false' when nav is open
        &[aria-haspopup="false"] {
            // update icon state for open
//            i { transform: rotate(-180deg); }

           +  ol {
                position: absolute;
                z-index: 1;
                width: 100%;
                box-shadow: 0 4px 12px rgba(0,0,0,0.08);
            }
        }
    }

    a {
        color: #2C2C2C;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        padding: 16px 20px;

        &:not(.active) {
            background: #FFFFFF;
            color: #333333;
        }

        &.active,
        &:hover,
        &:focus {
            background: #009FDA;
            color: #FFFFFF!important;
        }
    }

}
