.learner-result {
    padding-bottom: 46px;

    .page {
        margin: 0 auto;
        max-width: 960px;
        padding: 0 32px;
    }

    .call-to-action {
        h2 {
            font-size: 20px;
            font-weight: normal;
        }

        margin: 32px auto;
        max-width: 480px;
    }

    @media only screen and (max-width: 860px) {
        .overview {
            li.overview-card {
                width: calc(50% - 12px);

                &:nth-child(3n + 3) {
                    margin-right: 24px;
                }

                &:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 560px) {
        .overview {
            li.overview-card {
                width: 100%;

                &:nth-child(2n + 2),
                &:nth-child(3n + 3) {
                    margin-right: 24px;
                }
            }
        }
    }

    @media only screen and (max-width: 390px) {
        ul.page.start {
            padding: 0 12px;

        }
    }
}
