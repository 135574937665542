ul {
  font-size: 1.6rem;
}

h3 {
  font-size: 16px;
}

a i {
  font-size: 1.6rem;
}

.intro-overall {
  width: 296px;
}

.learner-result {
  .learner-skill {

    .detail-popup {
      opacity: 1;
      transition: width 2s ease-in;
    }

    .feedback {
      position: relative;
      padding-bottom: 24px;
      width: calc(33% + 12px);

      .answers-link {
        a {
          text-decoration: none;
          font-weight: bold;
        }

        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 32px;
        text-align: right;
        width: 100%;
        box-sizing: border-box;
      }

      .intro {
        section {
          padding-right: 12px;
          margin-right: 12px;
          border-right: 2px solid #F2F1F0;
        }
      }
    }

    .detail-feedback {
      position: relative;
      width: calc(100% - 336px);

      header {
        display: inline-block;
        vertical-align: middle;
      }

      i {
        vertical-align: middle;
        font-size: 20px;
        margin-left: -2px;
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .card {
      margin: 0;
      padding: 12px 16px 20px 16px;

      & > section {
        display: flex;
      }
    }

    .subskill {
      width: calc(100% - 296px);
      position: relative;

      .chart-and-info {
        padding-bottom: 28px;

        & > div {
          display: inline-block;
          vertical-align: top;
        }

        .detail-popup {
          width: 320px;
          left: -325px;
          top: -24px;
        }
      }

      .selection-dots {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    .responsive-plot-wrapper {
      width: 296px;
      height: 190px;
    }

    .intro-slide {
      max-width: calc(100% - 672px);

      h3:before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTEgMTVoLTJ2LTZoMnY2em0wLThoLTJWN2gydjJ6Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        content: ' ';
        display: inline-block;
        height: 24px;
        margin-right: 8px;
        width: 24px;
        vertical-align: middle;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .learner-result {
    .learner-skill {
      .card {

        & > section {
          display: block;
        }
      }

      .feedback {
        width: 100%;

        .intro {
          section {
            padding-right: 0;
            margin-right: 0;
            border-right: none;
          }
        }

        .answers-link {
          text-align: left;
        }
      }

      .cutoff-chart {
        margin-right: 0;
      }

      .detail-feedback {
        margin-top: 24px;
        margin-bottom: 24px;
        width: 100%;
      }

      .subskill {
        width: 100%;
        position: static;

        .chart-and-info {
          padding-bottom: 0;

          & > div {
            display: block;
          }

          .responsive-plot-wrapper {
            margin: 0 auto;
          }

          .detail-popup {
            left: 24px;
          }
        }

        .selection-dots {
          position: static;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .learner-result .learner-skill .cutoff-chart {
    margin-left: -16px;
    margin-right: -16px;
  }

  .learner-result .learner-skill .subskill .chart-and-info .detail-popup {
    width: 280px;
  }
}

@media only screen and (max-width: 340px) {
  .learner-result .learner-skill .subskill .chart-and-info .detail-popup {
    left: 0;
  }
}
