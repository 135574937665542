.selection-arrow {
    vertical-align: middle;
    cursor: pointer;

    .selection-arrow-path {
        fill: #009FDA;
    }
}

.selection-arrow-wrapper {
    display: inline-block;
}
