.dot {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    background-color: #CCCCCC;
    height: 12px;
    width: 12px;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    margin: 0 5px;
    outline: none;
    padding: unset;
    vertical-align: middle;

    &:hover {
        background-color: #89d8f5;
    }

    &:checked {
        height: 16px;
        width: 16px;
        background-color: #009FDA;
    }
}
