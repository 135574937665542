.detail-popup {
    background-color: #FFF;
    -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;

    .detail-popup-content {
        padding: 0 24px 0 24px;

        p {
            font-size: 16px;
            padding-top: 0;
        }
    }

    .close-button {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 10px 10px 0 0;
        font-size: 20px;
        line-height: 20px;
        text-align: right;
    }
}