// Default Banner styling

// @import '../../App.scss';

// Default root selector, if you go with custom, gotta redo this...
header[role="banner"] {
    padding: 1.6rem;
    margin: 0 0 2.4rem 0;

    h1 {
        font-size: 2.4rem;
        margin: 0;
        display: inline-block;
    }

    a + h1 {
        margin-left: 1.6rem;
    }

    i {
        font-size: 2.4rem;
        vertical-align: text-bottom;
        font-weight: 600;
    }
}

// Theme
header[role="banner"] {
    background: #FFFFFF;
    position: relative;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,0.16);

    i {
        color: #333333;
        font-size: 3.2rem;
        vertical-align: text-bottom;
        font-weight: 600;
    }
}