
body.IeltsiResults {
  background: #fff;

  a, u {
	color: #8625FF;
	font-weight: bold;
  }

  h1.title-info {

	color: #707070;
	font-size: 1.6rem;
	cursor: pointer;

	span {
	  background: #8625FF;
	  border-radius: 20px;
	  padding: 4px 12px;
	  font-size: 1.6rem;
	  color: white;
	  margin-right: 2.4rem
	}
  }

  h2 {
	color: #707070;
	font-size: 1.6rem;
  }

  ul li {
	color: #707070;
  }

  p {
	font-size: 1.6rem;
	color: #707070;
	line-height: 2.4rem;

	> span {
	  color: rgb(134, 37, 255);
	  font-weight: bold;
	}

	&.answerCorrect {
	  color: #00A4A1;
	  font-weight: bold;
	}

	&.answerIncorrect {
	  color: #C81F66;
	  font-weight: bold;
	}
  }
  section {
	display: flex;
	flex-direction: column;

	.check-answers {
	  background: #8625FF;
	  color: white;
	  cursor: pointer;
	  text-transform: uppercase;
	  box-shadow: 0 2px 8px #8625FF7A;
	  border-radius: 12px;
	  padding: 16px 24px;
	  font-weight: bold;
	  justify-content: center;
	  align-self: center;
	  margin-top: 24px;
	}
  }

  #radioBox {

	background: #0C7CB9;
	position: fixed;
	bottom: 0;
	display: flex;
	padding: 20px 36px;
	width: calc(100% - 72px);

	@media (min-width: 768px) {
	  	width: 670px;
	  	left: 50%;
	  	margin-left: -371px;
	}
  }

  #accordionForm {
	display: flex;
  }

  #feedbackBox {

	background: #0C7CB9;
	display: flex;
	border: 0;
	margin: 0;
	padding: 16px;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	transition: transform 1s ease-in-out;
	position: fixed;

	@media (min-width: 768px) {
	  width: 742px;
	  left: 50%;
	  margin-left: -371px;
	}

  }

  #root {
	height: 100%;
  }

  iframe {
	border: 0;
	width: 100%;
	height: 100%;
  }

  .regButton {
	text-transform: uppercase;
	color: #8625FF;
	font-size: 1.6rem;
	font-weight: bold;
	text-align: center;
	border: 0;
	display: block;
	margin: 24px auto 48px;
	cursor: pointer;
	text-decoration: none;
  }

  .MuiCollapse-wrapperInner {
	h3 {
	  font-weight: 400;
	}
  }

  .score {

	padding: 8.9rem 0 0 0;

	p#score {
	  font-size: 5.6rem;
	  font-weight: bold;
	  text-align: center;

	  span {
		font-size: 2.4rem;
		color: #707070;
	  }
	}
  }


}