div#skillHeader {
  list-style: none;
  display: flex;
  /*justify-content: space-between;*/
  flex-direction: row;
  padding: 0;
  height: 50px;
}

div#skillHeader div.menuItem {
  cursor: pointer;
  padding-right: 2em
}

div#skillHeader div.selected {
  color: #8625FF;
  font-weight: bold
}