//
// 1. SETTINGS
//

// 1.a COLOUR
// For product colour swatches

// Brand
$color-brand: #12B4BA;

// Action (links etc)
$color-action: #009FDA;
$color-action-active: #0E8682;

// Text
$color-text: #333333;
$color-text-light: #9B9B9B;
$color-text-inverse: #FFFFFF;

// Backgrounds
$color-background: #F2F1F0;
$color-background-light: #FFFFFF;
$color-background-mid: #E8E8E8;
$color-background-dark: #9B9B9B;
$color-background-darkest: #333;

// Borders
$color-border: $color-background-mid;

// 1.b SPACING
// For common units, breakpoints, baseline and such like

// Baseline
$bl-1: 8px;
$bl-2: 16px;
$bl-3: 24px;
$bl-4: 32px;
$bl-5: 40px;
$bl-6: 48px;
$bl-7: 56px;
$bl-8: 64px;
$bl-9: 72px;
$bl-10: 80px;

// Breakpoints
$bp-small: 375px;
$bp-medium: 768px;
$bp-default: 960px;
$bp-large: 1024px;
$bp-wide: 1440px;
$bp-ultra-wide: 1920px;

// Line-height
$line-max-copy: 640px;
$line-max-heading: 800px;

// 1.c TYPOGRAPHY
// For product typographcal styles

// Family
$font-family: "Source Sans Pro", sans-serif;
$font-family-serif: "Source Serif Pro", serif;

// Sizes
$font-label: 1.4rem;
$font-small: 1.6rem;
$font-medium: 2.0rem;
$font-heading: 2.4rem;
$font-large: 3.2rem;
$font-block: 8.0rem;

// Weights
$font-light: 100;
$font-regular: 400;
$font-bold: 600;
$font-boldest: 900; // Use for the "Black" font variants.

// Base values
$font-base-value: x-small;
$font-base-set: #{$font-small} #{$font-family};

//
// 2. TOOLS
//

// 2.a ALIASES
// For legible selctors, variables & such like



// 2.b ANIMATIONS
// For razzle-dazzle, transitions & such like
// Add as %placeholders and extend by selector at atom / molecule etc level

// 2.c MIXINS

// Typographical combos
@mixin font($fontSize, $lineHeight, $fontColor:null) {
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
}

//
// 3. BASE
//

// 3.a GLOBAL

/* BASE */

html {
    //font-size: $font-base-value;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
}

body {
    font: $font-base-set;
    font-weight: $font-regular;
    color: $color-text;
    background: $color-background;
    -webkit-font-smoothing: antialiased;
}

a {
    transition: all 0.4s ease-in-out;
    color: $color-action;

    &:hover,
    &:focus {
        color: $color-action-active;
    }
}

// 3.B HELPERS
// For any consistently reusble snippets

// Accessible hidden text
%hide {
    position:absolute;
    left:-10000px;
    width:1px;
    height:1px;
    overflow:hidden;
}

// Block reset
%block-me {
    margin: 0;
    padding: 0;
    display: block;
}

// 3.C RESETS

/* RESETS */

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

// Sensible tables
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// IOS button reset
button {
    -webkit-appearance: none;
    background: none;
    border: none;
}

// Some form bits that need to be explicitly set
textarea,
input,
button {
    font: $font-base-set;
    font-weight: $font-regular;
    color: $color-text;
    outline: none;
    padding: 0;
}

//
// DEFAULTS
//

.page {
    // Resets
    @extend %block-me;
    // Re-style
    max-width: $bp-default;
    margin: 0 auto;
    padding: 0 32px;

    @media screen and (max-width: 390px) {
        padding: 0 12px;
    }
}
