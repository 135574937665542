.login-form {
    .login-text-danger {
        color: red;
        font-size: 14px;
        height: 1.5em;
        margin: 6px 0;
        padding: 0;
    }

    .login-button {
        background-color: #0091EA;
        color: white;
        cursor: pointer;
        height: 40px;
        width: 100%;

        &:disabled {
            opacity: 0.65;
        }

        &:hover:enabled {
            background: #0078D1;
        }
    }

    .login-label {
        color: #555555;
        display: block;
        margin-bottom: 6px;
    }

    .login-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        border-bottom: 1px solid lightgrey;
        box-sizing: border-box;
        font-size: 16px;
        outline: none;
        padding: 2px 0;
        width: 100%;
    }
}
