.detail-feedback {
    header {
        display: inline-block;
        vertical-align: middle;
    }

    i {
        vertical-align: middle;
        font-size: 20px;
        margin-left: -2px;
        margin-right: 10px;
    }

    span.has-description {
        cursor: pointer;
    }
}
