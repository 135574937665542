.scroller {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 1.6rem 0;
  position: relative;

  ul {
    list-style: none;
    flex-direction: row;
    flex-grow: 2;
    padding: 0;
    display: flex;

    li {
      flex-direction: column;
      padding: 8px;
      margin: 4px;
      display: flex;
      align-items: center;
    }
  }

  svg  {
    font-size: 24px;
  }

  > :first-child {
    padding: 16px 16px 16px 0
  }

  > :last-child {
    padding: 16px 0 16px 16px
  }

  #questionContainer {
    //overflow-x: scroll;
  }

}