.overview {
    ol {
        padding-left: 0;
    }

    li.overview-card {
        margin: 0 24px 24px 0;
        height: 136px;
        display: inline-block;
        width: calc(33.3% - 16px);
        box-sizing: border-box;
        vertical-align: top;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }
}
