.page.review {
    .question-header {
        margin-bottom: 16px;
    }
}

@media only screen and (min-width: 960px) {
    .review footer.review-footer {
        display: none;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1023px),
only screen and (min-width: 1024px) {
    ul {
        &.page.review {

            .review-question {
                width: 100%;

                & > div {
                    padding-bottom: 25px;
                    display: inline-block;
                    width: 50%;

                    .manuallyAddedInputText{
                        display:none;
                    }

                    .inputText {
                        display: block;
                        width: 100%;

                    }
                }

                .slides {
                    width: 40%;
                    padding-left: 24px;
                    vertical-align: top;
                    display: inline-block;

                    li {
                        width: 100%;
                    }

                    li:last-of-type{
                        display:none;
                    }
                }
            }
        }
    }
}
