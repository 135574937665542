.overall-result h1 {
    margin: 4px 0 8px 0;
}

.overall-result p {
    padding: 8px 0;
}

.overall-result table td {
    width: 100px;
}

.overall-result {
    margin-bottom: 16px;
}