// Default Card styling

// @import '../../App.scss';

// Default root selector, if you go with custom, gotta redo this...
.card {
    // resets
    margin: 0 1.6rem 1.6rem 1.6rem;
    padding: 1.6rem;
    display: block;

    &.inner {
        margin: 0;
    }
}

// Theme
.card {
    position: relative;

    &:not(.inner) {
        -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.24);
        -moz-box-shadow: 0 2px 4px rgba(0,0,0,.24);
        box-shadow: 0 2px 4px rgba(0,0,0,.24);
    }

    &:not(.clear) {
        background: none;
        background: #FFFFFF;
    }
}
