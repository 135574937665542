section.review-question {

    ul, ol {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    p.correct {
        font-weight: bold;
    }

    p {
        &.correct,
        &.incorrect {
            margin-left: 16px;

        }
    }

    .correct {
        color: #009688;
    }

    .incorrect {
        color: #e74641;
    }

    overflow: hidden;

    div {

        .manuallyAddedInputText {
            display: block;
        }

        .inputText {
            display: none;
        }
    }

    .slides {

        width: 200%;
        overflow: auto;

        li {
            width: 50%;
            display: inline-block;
            vertical-align: top;

            &[hidden] {
                display: none;
            }
        }
    }
}
