.examSessions {
    .card {
        -webkit-box-shadow: 0 2px 4px rgba(0,0,0,0.24);
        -moz-box-shadow: 0 2px 4px rgba(0,0,0,0.24);
        box-shadow: 0 2px 4px rgba(0,0,0,0.24);

        header {
            margin-bottom: 8px;
        }
    }



    table {
        border: 1px solid lightgray;
    }

    ul {
        list-style-type: disc;
    }

    p {
        padding: 0;
        margin: 8px 0;
    }
}