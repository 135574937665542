.compass {

  table {
	td {
	  padding: 32px 0;
	  text-align: center;
	  border-bottom: 1px solid #dedddd;
	}
	th {
	  font-weight: 500
	}
  }

}
