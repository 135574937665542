.user-login {
    .card {
        margin: 0 auto;
        max-width: 480px;
    }

    .icon-wrapper {
        text-align: center;
    }

    i {
        color: #555555;
        font-size: 64px;
    }
}
