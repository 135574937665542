.selection-dots {
    overflow: hidden;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;

    legend {
        display: none;
    }
}
