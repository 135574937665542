ol.score-chart-legend {
    padding-left: 0;
    width: 296px;
    cursor: pointer;
    position: fixed;
    bottom: 0;

    > header {
        display: none;
    }

    .card {
        margin: 0;
        box-sizing: border-box;
        cursor: pointer;
        height: 84px;
        padding: 8px;

        &:hover {
            outline: none;
        }

        header {
            font-size: 12px;
            font-weight: bold;
        }
    }

    &.hidden .card {
        section {
            display: none;
        }

        header {
            display: block;
            background-color: #009FDA;
            color: #FFF;
            font-size: 12px;
            padding: 12px 0 0 8px;
            height: 36px;
        }

        height: 36px;
        padding: 0;
    }
}
