section.errorInformation {

  div.errorText {
    width: 100%;
    max-width: 900px;
    margin: auto;
    padding-top: 2em;

    div {
      background-color: white;
      text-align: center;
      h1 {
        margin: 0;
      }
    }
  }

  div.errorIllustration {
    position: fixed;
    top: 0;
    z-index: -9;
    opacity: 0.4;
    background-repeat: no-repeat;

    &.status500, &.status501, &.status502, &.status503, &.status504 {
      background-image: url("/img/brokenBicycle.jpg");
      width: 1500px;
      height: 1000px;
      left: calc(50% - 750px);
    }
    &.status404 {
      background-image: url("/img/compass.jpg");
      width: 934px;
      height: 1401px;
      left: calc(50% - 467px);
    }
    &.status403, &.status401  {
      background-image: url("/img/padlocks.jpg");
      width: 1500px;
      height: 1000px;
      left: calc(50% - 750px);
    }
  }
}